<template>
  <div>
    <!-- <Sidebar /> -->
    <HeaderPage />
    <div class="mt-7 pt-7">
      <div class="ps-3 pt-3">
        <span class="" style="font-size: 26px;">{{
          $t("message.my-page")
        }}</span>
        <hr class="me-3 mt-3 mb-4 black--text" />
      </div>

      <div v-if="isLoading">
        <v-skeleton-loader
          v-bind="attrs"
          type="article"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-bind="attrs"
          width="300"
          type="article, list-item-three-line"
        ></v-skeleton-loader>
        <v-skeleton-loader
          v-bind="attrs"
          type="image"
          height="15"
          class="ps-0 mt-1 ml-4"
          width="80"
        ></v-skeleton-loader>        
      </div>

      <v-container v-else>
        <div id="login-confirmation" class="">
          <div class="mb-4">
            <p class="ps-3 text_title">{{ $t("message.login-information") }}</p>
          </div>
          <div>
            <v-row>
              <v-col>
                <v-card
                  cols="4"
                  class="pe-1 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  {{ $t("message.label-email") }}
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  {{ userProfile.email }}
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
            <v-row>
              <v-col>
                <v-card
                  cols="4"
                  class="text-left pe-1 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  {{ $t("message.member-id") }}
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  {{ userProfile.email }}
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
            <v-row>
              <v-col cols="">
                <v-card
                  cols="4"
                  class="text-left pe-1 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  {{ $t("message.label-password") }}
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  *******
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
          </div>
        </div>
        <div id="customer-profile" class="mt-5">
          <div class="mb-4">
            <p class="ps-3 text_title">{{ $t("message.customer-profile") }}</p>
          </div>
          <div>
            <v-row>
              <v-col>
                <v-card
                  cols="4"
                  class="pe-1 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  {{ $t("message.label-name") }}
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  {{ userProfile.customer_name }}
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
            <v-row>
              <v-col>
                <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                  {{ $t("message.furigana") }}
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  <span v-if="userProfile.furigana">
                    {{ userProfile.furigana }}
                  </span>
                  <span v-else>
                    {{""}}
                  </span>
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
            <v-row>
              <v-col>
                <v-card class="pe-1  ps-5" style="font-size: 14px" flat tile>
                  {{ $t("message.gender") }}
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  {{ formatGender(userProfile.gender) }}
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />

            <v-row>
              <v-col>
                <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                  {{ $t("message.label-bod") }}
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  {{ userProfile.bod }}
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
            <v-row>
              <v-col>
                <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                  {{ $t("message.label-postal") }}
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  〒{{ formatPostalcode(userProfile.postal_code) }}
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
            <v-row>
              <v-col cols="4">
                <v-card class="pe-1 ps-5" style="font-size: 14px;" flat tile>
                  {{ $t("message.label-address") }} ({{
                    $t("message.label-prefecture")
                  }})
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  {{ userProfile.prefecture }}
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
            <v-row>
              <v-col>
                <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                  {{ $t("message.label-address") }} ({{
                    $t("message.label-city")
                  }})
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  {{ userProfile.city }}
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
            <v-row>
              <v-col>
                <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                  {{ $t("message.label-address") }} ({{
                    $t("message.label-district")
                  }})
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  <div v-if="!userProfile.district">
                    {{""}}
                  </div>
                  <div v-else>
                    {{ userProfile.district }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
            <v-row>
              <v-col>
                <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                  {{ $t("message.label-address-ward") }}
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  <div v-if="!userProfile.ward">
                    {{""}}
                  </div>
                  <div v-else>

                  {{ userProfile.ward }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
            <v-row>
              <v-col>
                <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                  {{ $t("message.label-address-street_address") }}
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  <div v-if="!userProfile.street_address">
                    {{""}}
                  </div>
                  <div v-else>

                  {{ userProfile.street_address }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
            <v-row>
              <v-col>
                <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                  {{ $t("message.label-building") }}
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  <div v-if="!userProfile.building">
                    {{""}}
                  </div>
                  <div v-else>

                  {{ userProfile.building }}
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
            <v-row>
              <v-col>
                <v-card class="pe-1 ps-5" style="font-size: 14px" flat tile>
                  {{ $t("message.phone-number") }}
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card
                  class="text-left pe-1 me-3 ps-5"
                  style="font-size: 14px"
                  flat
                  tile
                >
                  {{ userProfile.phone }}
                </v-card>
              </v-col>
            </v-row>
            <hr class="mx-4 black--text" />
          </div>
        </div>
        <div class="mt-5">
          <router-link to="/" style="text-decoration: none">
            <v-btn
              depressed
              text
              color="#707070"
              style="color: #707070; margin-left: 10px;text-transform: none;"
              width="180px"
              height="35px"
              class="p-0"
            >
              <!-- {{ $t("message.btn-edit") }} -->
              <v-icon left>
                mdi-chevron-left
              </v-icon>
              {{ $t("message.back-to-top") }}
            </v-btn>
          </router-link>
        </div>
      </v-container>
    </div>

    <div class="d-flex justify-center mt-5">
      <router-link to="/account/update" style="text-decoration: none">
        <v-btn
          depressed
          color="#707070"
          style="color: white"
          width="200px"
        >
          {{ $t("message.update-profile") }}
          <v-icon right dark>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </router-link>
    </div>

    <!-- <v-col cols="12" md="12" xs2>

      <v-btn
          v-if="userProfile.is_integrate_line == 1"
          :disabled="true"
          width="200px"
          height="35px" class="line-button" style="display:flex; margin: 0px auto; padding-left: 0px;padding-right:5px; background-color: rgb(150,150,150) !important; color: white; height: 45px; weight: 500">
        <img src="../../public/LINE_Brand_icon.png" alt="line" style="filter:grayscale(1)" class="width: 300px" height="45px">
        {{ $t("message.btn-line-integrate") }}
      </v-btn>
      <v-btn
          v-else
          width="200px"
          height="35px" @click.prevent="lineIntegrasi()" class="line-button" style="display:flex; margin: 0px auto; padding-left: 0px;padding-right:5px; background-color: #06C755; color: white; height: 45px; weight: 500">
        <img src="../../public/LINE_Brand_icon.png" alt="line" class="width: 300px" height="45px">
        {{ $t("message.btn-line-integrate") }}
      </v-btn>
    </v-col> -->
    
    <div style="margin-bottom: 8rem">
      <CategoryFooter />
    </div>
    <Sidebar />
  </div>
</template>

<script>
// import Sidebar from "../components/Sidebar.vue";
import Sidebar from '../components/developmentv2/Sidebar.vue'
import HeaderPage from '../components/developmentv2/HeaderPage.vue'
import Back from "../components/Back.vue";
import CategoryFooter from "../components/CategoryFooter.vue";

export default {
  name: "Account",
  components: { Sidebar, BackComponent: Back, CategoryFooter, HeaderPage },
  data() {
    return {
      text: this.$t("message.title-edit-account-success-message"),
      // isActive: ""
      isLoading: false,
      attrs: {        
        class: "mb-1",
        boilerplate: false,
      },
    };
  },  
  created() {
    this.dispacthProfile();
    // this.is_active_integrasi();
  },
  methods: {
    formatPostalcode(postalcode) {
      if(postalcode != null) {
        let newPostcode = postalcode.slice(0, 3)+'-'+postalcode.slice(3)
        return newPostcode
      } else {
        return
      }
    },
    async dispacthProfile() {
      this.isLoading = true
      await this.$store.dispatch("account_module/fetchUserProfile");
      this.isLoading = false
    },
    formatGender(gender) {
      if (gender === 1) return this.$t("message.label-sex-male");
      else return this.$t("message.label-sex-female");
    },
    // lineIntegrasi() {
    //   this.$store.dispatch("auth_module/line_integration");
    // },
  },
  computed: {

    userProfile() {
      return this.$store.state.account_module.userProfile;
    },
    loginConfirmation() {
      return ["Email Address", "Member Id", "Password"];
    },
  },
};
</script>

<style>
.spacing {
  /* padding-left: 0px !important; */
  padding-right: 0px !important;
  margin: 0px !important;
}
.header-title {
  font-family: roboto;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-top: -25px;
  height: 25px;
}
.user-profile {
  font-weight: 500;
}
.text_title {
  /* text-transform: capitalize; */
  border-left: 10px solid #ff0090;
  font-size: 16px;
  color: #424242;
}
</style>
